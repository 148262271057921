import { Router } from '@reach/router';
import React from 'react';
import RedirectHome from '../components/redirect-home';

function SignUp(): JSX.Element {
  return (
    <Router>
      {/* Error from installing @types/react-helmet and @types/react-redux */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <RedirectHome default={true} />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
    </Router>
  );
}

SignUp.displayName = 'SignUp';

export default SignUp;
